import { graphql } from 'gatsby'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css, theme } from 'twin.macro'
import Hero from '../components/hero'
import Layout from '../components/layout'
import LegalContent from '../components/legal-content'
import SubNav from '../components/sub-nav'
import { flatten } from '../utils/data'

const LegalPage = ({ data: { legal }, pageContext: { slug }, location }) => {
  const page = useMemo(() => flatten(legal || {}, ['hero']), [legal])
  const links = useMemo(
    () =>
      get(page, 'pages', []).map((p) => ({
        label: p.title,
        link: `/${kebabCase(p.identifier)}`,
      })),
    [page]
  )
  const legalContent = useMemo(
    () => get(page, 'pages', []).find((p) => kebabCase(p.identifier) === slug),
    [page]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location}>
      <section css={tw`relative bg-smoke-grey`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          titleHeadingType="h1"
          description={get(page, 'hero.descriptionNode')}
          extended
        />
      </section>
      <SubNav 
        links={links} 
        clipBackground 
        selectBackground={theme`colors.primary.100`}
        style={css`
        ul {
          ${tw`xl:(col-span-16)`}
        }
        li {
          ${tw`text-base text-primary-100 font-bold lg:(mr-20 ml-0)`}
        }
        `}
        />

      {/**
       *         style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-mobile-header lg:top-desktop-header`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
        `}
        selectBackground={theme`colors.white`}
        currentPath={activeLink}

       */}
      <LegalContent
        title={legalContent.title}
        date={legalContent.date}
        content={legalContent.contentNode}
        navigateToLabel={get(page, 'navigateToLabel')}
        style={tw`pt-20 pb-24 lg:py-32`}
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    legal: PropTypes.object.isRequired,
  }),
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }),
  location: PropTypes.object,
}

export default LegalPage

export const query = graphql`
  query LegalQuery($locale: String!) {
    legal: datoCmsHkGiLegal(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      navigateToLabel
      pages {
        identifier
        title
        date
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
